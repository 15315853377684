import { Component, ElementRef, ViewChild, AfterViewChecked, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { UntypedFormControl, Form } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { YukkApi } from 'src/app/service/yukkapi.service';
import * as moment from 'moment';
import { forkJoin, of } from 'rxjs';
import { RoutingService } from 'src/app/service/routing.service';
import { AuthService } from 'src/app/service/auth.service';
import { ConfigService } from 'src/app/service/config.service';
import { SupplyChainService } from '../../news-network/network-data.service';
import { SupplyChainData } from '../../news-network/network-data.interface';

/**
 * this component is display in the company page it display the sentiment, colume, event, peergroup, etc...
 */
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-chartmap-company',
  templateUrl: './chartmap-company.component.html',
  styleUrls: ['./chartmap-company.component.scss']
})
export class ChartmapCompanyComponent implements AfterViewChecked {

  @ViewChild('expansionContainer') expansionContainer: ElementRef;
  contentHeight: number;

  math = Math;

  /**
   * list of perrgeoup company
   */
  peergroup: any;

  /**
   * columns to display
   */
  ifilter: any;

  /**
   * query parameters
   */
  qparam: any;

  /**
   * company data info
   */
  entity: any;

  /**
   * list of tags
   */
  topicloud: any;

  /**
   * treemap of the company
   */
  treemapsort: any;

  /**
   * query parameters refresh
   */
  previousValue: any;

  /**
   * columns array
   */
  array_filter = ['Events', 'Peergroup Comparison', 'Entity'];

  /**
   * active tag
   */
  tagword: string;

  /**
   * entity selected from network graph
   */
  networkEntity: any;

  /**
   * array of events
   */
  events: any;

  /**
   * array of score events
   */
  scoreEvents: any;

  /**
   * array of alert events
   */
  alertEvents: any;

  /**
   * array of flag events
   */
  flagWarningEvents: any;

  /**
   * array of flag events
   */
  flagCautionEvents: any;

  showWarningFlag = false;
  showCautionFlag = false;

  /**
   * array of top esg events
   */
  scoreEventsESG: any;

  /**
   * array of environmental score events
   */
  scoreEventsE: any;

  /**
   * array of social score events
   */
  scoreEventsS: any;

  /**
   * array of governance score events
   */
  scoreEventsG: any;

  /**
   * current E/S/G scores
   */
  scoresESG = {
    scoreESG: null,
    scoreE: null,
    scoreS: null,
    scoreG: null,
    scoreLowest: ''
  };

  /**
   * array of top esg alert events
   */
  alertEventsESG: any;

  /**
   * array of environmental alert events
   */
  alertEventsE: any;

  /**
   * array of social alert events
   */
  alertEventsS: any;

  /**
   * array of governance alert events
   */
  alertEventsG: any;

  /**
   * current E/S/G alert scores
   */
  alertESG = {
    scoreESG: null,
    scoreE: null,
    scoreS: null,
    scoreG: null,
    scoreLowest: ''
  };

  /**
   * when there is no data for a particular mosaic
   */
  nodata = {};

  /**
   * loading mosaic
   */
  loading = {};

  /**
   * when a particular mosaic is ready
   */
  iready = {};

  /**
   * project based on config file
   */
  project: any;

  /**
   * is one of the scores focused
   */
  fromScoreFocus: boolean;

  /**
   * is one of the scores focused
   */
  fromAlertScore: boolean;

  sentimentEventsCount = null;
  sentimentEventsCountAll = null;
  sentimentEventsTimeframe = null;
  scoreEventsCount = null;
  scoreEventsCountAll = null;
  scoreEventsTimeframe = null;
  alertEventsCount = null;
  alertEventsCountAll = null;
  alertEventsTimeframe = null;
  flagWarningEventsCount = null;
  flagWarningEventsCountAll = null;
  flagWarningEventsTimeframe = null;
  flagCautionEventsCount = null;
  flagCautionEventsCountAll = null;
  flagCautionEventsTimeframe = null;

  // additional param for llm chat charts widgets path: ../../../project/llm/chat-charts/chat-charts.component.ts
  customScore: string;


  /**
   * sorting events
   */
   eventsSortedBy = 'importance';

  sortEventsByToogle = [
    { value: 'importance', label: 'Importance', icon: 'scale-balance' , class: 'sorting-importance'},
    { value: 'count', label: 'Count', icon: 'pound', class: 'sorting-count' },
  ];

  supplyChain: any;

  /**
   * query parameters subscribe and get all the data: treemap, events, tagcloud, etc...
   */
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private yukkApi: YukkApi,
    public routing: RoutingService,
    public auth: AuthService,
    public config: ConfigService,
    private cdr: ChangeDetectorRef,
    private supplyChainService: SupplyChainService
  ) {

    this.customScore = this.auth.scorelabSettings.defaultScore;


    this.auth.showWarningFlag$.subscribe((value) => setTimeout(() => this.showWarningFlag = value, 0));

    this.auth.showCautionFlag$.subscribe((value) => setTimeout(() => this.showCautionFlag = value, 0));

    this.project = this.config.appConfig.routing.project;

    this.route.queryParams.subscribe(qparam => {

      this.qparam = JSON.parse(JSON.stringify(qparam));

      if (this.qparam.custom_score) {
        this.customScore = this.qparam.custom_score;
      } else {
        this.customScore = this.auth.scorelabSettings.defaultScore;
      }

      if (!(this.customScore === 'sentiment') && (this.routing.isFolio() || (qparam.type && ((qparam.type === 'company') || (qparam.type === 'pne') || ['industry', 'supersector', 'sector', 'subsector'].includes(qparam.type))) || (qparam.isin))) {
        this.array_filter = ['Score Events', 'Peergroup Comparison', 'Events', 'Entity'];
      }


      this.fromScoreFocus = ((routing.isChart() && (routing.isFolio() || routing.isMarket())) && qparam?.score);

      this.fromAlertScore = (this.fromScoreFocus && (this.auth.scoreFocusedType === 'alert'));

      if (this.fromScoreFocus) {
        // this.qparam.time = this.auth.scoreFocusedTimeframe;
        // showing chosen event for event based alert at the top of events mosaic
        if (qparam?.scoreevent && (qparam?.eventid)) {
          const isESG = (qparam.score === 'esg') ? 'esg' : '';
          const isAlert = (this.fromAlertScore) ? 'alert' : '';
          const targetId = qparam.eventid + '-' + isESG + '' + isAlert;
          // const targetId = this.qparam.eventid;
          setTimeout(() => {
            const targetElement = document.getElementById(targetId);
            // @ts-ignore
            // targetElement.scrollIntoViewIfNeeded();
            this.scrollIntoViewIfNotVisible(targetElement);
          }, 500);
        }
      }
  
      if (this.routing.reFresh(this.qparam, this.previousValue, ['tag']) && this.routing.isNetwork()) {
        this.supplyChainService.getSupplyChain().subscribe((data: SupplyChainData) => {
          if (data) {
            const { motherCompany, suppliers } = data;
            this.supplyChain = { motherCompany, suppliers };
            
            // Continue processing after supply chain data is set
            this.processSupplyChainData();
        } 
        });
      }

      this.tagword = decodeURI(qparam.tag).split('|')[0];
      if (this.routing.reFresh(this.qparam, this.previousValue, ['update', 'updateFlag', 'updatePayload', 'type', 'id', 'isin', 'time', 'newsday', 'lang', 'feed', 'categories', 'continents', 'countries', 'factuality', 'temporality', 'ranks', 'period', 'score_type', 'score'])) {

        // this.nodata = false;
        // this.loading = true;
        // this.iready = false;
        this.array_filter.forEach(item => {
          this.nodata[item] = false;
          this.loading[item] = true;
          this.iready[item] = false;
        });

        this.nodata['Flag Events'] = {};
        this.loading['Flag Events'] = {};
        this.iready['Flag Events'] = {};
        this.nodata['Flag Events']['Warning'] = false;
        this.loading['Flag Events']['Warning'] = true;
        this.iready['Flag Events']['Warning'] = false;
        this.nodata['Flag Events']['Caution'] = false;
        this.loading['Flag Events']['Caution'] = true;
        this.iready['Flag Events']['Caution'] = false;

        if (this.fromAlertScore) {
          this.nodata['Alert Events'] = false;
          this.loading['Alert Events'] = true;
          this.iready['Alert Events'] = false;
        }

        this.peergroup = undefined;
        this.entity = {
          currency: '',
          logo: '',
          price: '',
          price_change: '',
          price_change_diff: '',
        };

        if (!(this.customScore === 'sentiment') && (this.routing.isFolio() || (qparam.type && ((qparam.type === 'company') || (qparam.type === 'pne') || ['industry', 'supersector', 'sector', 'subsector'].includes(qparam.type))) || (qparam.isin))) {

          if (!(this.routing.isFolio() && !this.qparam.portfolioId) && !this.routing.isQuery() && !this.routing.isSearch() && !(this.qparam.type === 'pne') && !(this.qparam.type === 'hierarchy')) {
            if (this.routing.isMobile()) {
              this.ifilter = new UntypedFormControl(['Score Events']);
            } else {
              this.ifilter = new UntypedFormControl(['Score Events', 'Topic List', 'Peergroup Comparison']);
            }
            if (this.config.appConfig.setup.useStorage) {
              if (localStorage.getItem('score_company_filter') != null) {
                const score_company_filter = localStorage.getItem('score_company_filter').split(',');
                this.ifilter.setValue(score_company_filter);
              }
            }
          } else {
            if (this.routing.isMobile()) {
              this.ifilter = new UntypedFormControl(['Score Events']);
            } else {
              this.ifilter = new UntypedFormControl(['Score Events', 'Topic List']);
            }
            if (this.config.appConfig.setup.useStorage) {
              if (localStorage.getItem('score_mosaics_filter') != null) {
                const score_mosaics_filter = localStorage.getItem('score_mosaics_filter').split(',');
                this.ifilter.setValue(score_mosaics_filter);
              }
            }
          }

        } else {

          if (!(this.routing.isFolio() && !this.qparam.portfolioId) && !this.routing.isQuery() && !this.routing.isSearch() && !(this.qparam.type === 'pne') && !(this.qparam.type === 'hierarchy')) {
            if (this.routing.isMobile()) {
              this.ifilter = new UntypedFormControl(['Events']);
            } else {
              this.ifilter = new UntypedFormControl(['Events', 'Topic List', 'Peergroup Comparison']);
            }
            if (this.config.appConfig.setup.useStorage) {
              if (localStorage.getItem('company_filter') != null) {
                const company_filter = localStorage.getItem('company_filter').split(',');
                this.ifilter.setValue(company_filter);
              }
            }
          } else {
            if (this.routing.isMobile()) {
              this.ifilter = new UntypedFormControl(['Events']);
            } else {
              this.ifilter = new UntypedFormControl(['Events', 'Topic List']);
            }
            if (this.config.appConfig.setup.useStorage) {
              if (localStorage.getItem('mosaics_filter') != null) {
                const mosaics_filter = localStorage.getItem('mosaics_filter').split(',');
                this.ifilter.setValue(mosaics_filter);
              }
            }
          }

        }

        if (this.qparam.mosaic === 'scoreevents') {
          this.ifilter = new UntypedFormControl(['Score Events']);
        }
        if (this.qparam.mosaic === 'events') {
          this.ifilter = new UntypedFormControl(['Events']);
        }
        if (this.qparam.mosaic === 'peergroup') {
          this.ifilter = new UntypedFormControl(['Peergroup Comparison']);
        }
        if (this.qparam.mosaic === 'topic') {
          this.ifilter = new UntypedFormControl(['Topic List']);
        }
        if (this.qparam.mosaic === 'entity') {
          this.ifilter = new UntypedFormControl(['Entity']);
        }

        // TOPICLOUD ---------------------------------------------

        if (!this.routing.isNetwork()) {
          this.yukkApi.tagcloud(this.qparam).subscribe(result => {
            this.topicloud = result.tagcloud;
            this.nodata['Topic List'] = false;
            if (this.topicloud.length > 0) {
              this.iready['Topic List'] = true;
            } else {
              this.iready['Topic List'] = false;
              this.loading['Topic List'] = false;
            }
          }, error => {
            this.nodata['Topic List'] = true;
            this.loading['Topic List'] = false;
          });
        }

        // FLAG EVENTS ---------------------------------------

        if (!this.routing.isNetwork() && this.auth.scorelabSettings.defaultFlag && (this.auth.scorelabSettings.defaultFlag.score_type !== 'sentiment') && (this.auth.scorelabSettings.defaultFlag.score_type !== 'volume') && (this.auth.scorelabSettings.defaultFlag.score_type !== this.customScore) && (!(this.auth.scorelabSettings.defaultFlag.score_type === 'credit_risk')) && (this.routing.isFolio() || (qparam.type && ((qparam.type === 'company') || (qparam.type === 'pne') || ['industry', 'supersector', 'sector', 'subsector'].includes(qparam.type))) || (qparam.isin))) {

          if (this.auth.scorelabSettings.defaultFlag.score_type === 'credit_risk') {
            this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
              requestScore: this.auth.scorelabSettings.defaultFlag.score_type
            }), 'chart').subscribe(result2 => {

              const crScore = (result2['score_ts'][result2['score_ts'].length - 1]['score'] !== null) ? result2['score_ts'][result2['score_ts'].length - 1]['score'] : null;

              forkJoin([
                this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                  with_top_events: true,
                  with_all_events: true,
                  top_events_sign: (crScore && (crScore > 0)) ? 'neg' : null,
                  requestScore: this.auth.scorelabSettings.defaultFlag.score_type
                }), 'chart'),
                this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                  with_top_events: true,
                  with_all_events: true,
                  top_events_sign: (crScore && (crScore > 0)) ? 'neg' : null,
                  time: this.checkLongTimeframe2() ? this.checkFocusedTimeframe(this.qparam.time, 465) : (this.qparam.time ? this.qparam.time : null),
                  requestScore: this.auth.scorelabSettings.defaultFlag.score_type
                }), 'chart')]).subscribe(result => {
                const resCopy = JSON.parse(JSON.stringify(result));
                const resCopy2 = JSON.parse(JSON.stringify(result));
                this.setFlagEventsInfo(resCopy[0], 'warning');
                this.setFlagEventsInfo(resCopy[1], 'caution');
                this.flagWarningEvents = resCopy2[0]['top_events'] ? resCopy2[0]['top_events'] : [];
                let nonEmptyWarning = false;
                this.flagWarningEvents.forEach(item => {
                  if (item.contrib_amount > 0) {
                    nonEmptyWarning = true;
                  }
                });
                this.flagCautionEvents = resCopy2[1]['top_events'] ? resCopy2[1]['top_events'] : [];
                let nonEmptyCaution = false;
                this.flagCautionEvents.forEach(item => {
                  if (item.contrib_amount > 0) {
                    nonEmptyCaution = true;
                  }
                });
                this.nodata['Flag Events']['Warning'] = false;
                this.nodata['Flag Events']['Caution'] = false;
                if (nonEmptyWarning) {
                  this.iready['Flag Events']['Warning'] = true;
                } else {
                  this.iready['Flag Events']['Warning'] = false;
                  this.loading['Flag Events']['Warning'] = false;
                }
                if (nonEmptyCaution) {
                  this.iready['Flag Events']['Caution'] = true;
                } else {
                  this.iready['Flag Events']['Caution'] = false;
                  this.loading['Flag Events']['Caution'] = false;
                }

                const timeframeShort = this.checkTimeframe() ? this.checkFocusedTimeframe(this.qparam.time, 100) : (this.qparam.time ? this.qparam.time : null);
                const timeframeLong = this.checkLongTimeframe2() ? this.checkFocusedTimeframe(this.qparam.time, 465) : (this.qparam.time ? this.qparam.time : null);
                const timeObjShort = this.yukkApi.myFromTo(timeframeShort);
                const timeObjLong = this.yukkApi.myFromTo(timeframeLong);
                const timeParamShort = moment(timeObjShort.date1).subtract(timeObjShort.period, 'days').format('YYYY-MM-DD') + '--' + timeObjShort.date1;
                const timeParamLong = moment(timeObjLong.date1).subtract(timeObjLong.period, 'days').format('YYYY-MM-DD') + '--' + timeObjLong.date1;

                forkJoin([
                  this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                    with_top_events: true,
                    with_all_events: true,
                    top_events_sign: (crScore && (crScore > 0)) ? 'neg' : null,
                    time: timeParamShort,
                    requestScore: this.auth.scorelabSettings.defaultFlag.score_type
                  }), 'chart'),
                  this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                    with_top_events: true,
                    with_all_events: true,
                    top_events_sign: (crScore && (crScore > 0)) ? 'neg' : null,
                    time: timeParamLong,
                    requestScore: this.auth.scorelabSettings.defaultFlag.score_type
                  }), 'chart')]).subscribe(result3 => {

                  this.flagWarningEvents.forEach((warning, index) => {
                    if (result3[0]['top_events'].filter(top => (top.id === warning.id)).length > 0) {
                      this.flagWarningEvents[index].contrib_amount_prev = result3[0]['top_events'].filter(top => (top.id === warning.id))[0].contrib_amount;
                      this.flagWarningEvents[index].contrib_amount_importance_prev = result3[0]['top_events'].filter(top => (top.id === warning.id))[0].contrib_amount_importance;
                    }
                  });

                  this.flagCautionEvents.forEach((caution, index) => {
                    if (result3[1]['top_events'].filter(top => (top.id === caution.id)).length > 0) {
                      this.flagCautionEvents[index].contrib_amount_prev = result3[1]['top_events'].filter(top => (top.id === caution.id))[0].contrib_amount;
                      this.flagCautionEvents[index].contrib_amount_importance_prev = result3[1]['top_events'].filter(top => (top.id === caution.id))[0].contrib_amount_importance;
                    }
                  });

                });

              }, error => {
                this.nodata['Flag Events']['Warning'] = true;
                this.loading['Flag Events']['Warning'] = false;
                this.nodata['Flag Events']['Caution'] = true;
                this.loading['Flag Events']['Caution'] = false;
              });

            }, error => {
              this.nodata['Flag Events']['Warning'] = true;
              this.loading['Flag Events']['Warning'] = false;
              this.nodata['Flag Events']['Caution'] = true;
              this.loading['Flag Events']['Caution'] = false;
            });
          } else {

            forkJoin([
              this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                with_top_events: true,
                with_all_events: true,
                requestScore: this.auth.scorelabSettings.defaultFlag.score_type
              }), 'chart'),
              this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                with_top_events: true,
                with_all_events: true,
                time: this.checkLongTimeframe2() ? this.checkFocusedTimeframe(this.qparam.time, 465) : (this.qparam.time ? this.qparam.time : null),
                requestScore: this.auth.scorelabSettings.defaultFlag.score_type
              }), 'chart')]).subscribe(result => {

              const resCopy = JSON.parse(JSON.stringify(result));
              const resCopy2 = JSON.parse(JSON.stringify(result));
              this.setFlagEventsInfo(resCopy[0], 'warning');
              this.setFlagEventsInfo(resCopy[1], 'caution');
              this.flagWarningEvents = resCopy2[0]['top_events'] ? resCopy2[0]['top_events'] : [];
              let nonEmptyWarning = false;
              this.flagWarningEvents.forEach(item => {
                if (item.contrib_amount > 0) {
                  nonEmptyWarning = true;
                }
              });

              this.flagCautionEvents = resCopy2[1]['top_events'] ? resCopy2[1]['top_events'] : [];
              let nonEmptyCaution = false;
              this.flagCautionEvents.forEach(item => {
                if (item.contrib_amount > 0) {
                  nonEmptyCaution = true;
                }
              });

              this.nodata['Flag Events']['Warning'] = false;
              this.nodata['Flag Events']['Caution'] = false;
              if (nonEmptyWarning) {
                this.iready['Flag Events']['Warning'] = true;
              } else {
                this.iready['Flag Events']['Warning'] = false;
                this.loading['Flag Events']['Warning'] = false;
              }
              if (nonEmptyCaution) {
                this.iready['Flag Events']['Caution'] = true;
              } else {
                this.iready['Flag Events']['Caution'] = false;
                this.loading['Flag Events']['Caution'] = false;
              }

              const timeframeShort = this.checkTimeframe() ? this.checkFocusedTimeframe(this.qparam.time, 100) : (this.qparam.time ? this.qparam.time : null);
              const timeframeLong = this.checkLongTimeframe2() ? this.checkFocusedTimeframe(this.qparam.time, 465) : (this.qparam.time ? this.qparam.time : null);
              const timeObjShort = this.yukkApi.myFromTo(timeframeShort);
              const timeObjLong = this.yukkApi.myFromTo(timeframeLong);
              const timeParamShort = moment(timeObjShort.date1).subtract(timeObjShort.period, 'days').format('YYYY-MM-DD') + '--' + timeObjShort.date1;
              const timeParamLong = moment(timeObjLong.date1).subtract(timeObjLong.period, 'days').format('YYYY-MM-DD') + '--' + timeObjLong.date1;

              forkJoin([
                this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                  with_top_events: true,
                  with_all_events: true,
                  time: timeParamShort,
                  requestScore: this.auth.scorelabSettings.defaultFlag.score_type
                }), 'chart'),
                this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                  with_top_events: true,
                  with_all_events: true,
                  time: timeParamLong,
                  requestScore: this.auth.scorelabSettings.defaultFlag.score_type
                }), 'chart')]).subscribe(result2 => {

                this.flagWarningEvents.forEach((warning, index) => {
                  if (result2[0]['top_events'].filter(top => (top.id === warning.id)).length > 0) {
                    this.flagWarningEvents[index].contrib_amount_prev = result2[0]['top_events'].filter(top => (top.id === warning.id))[0].contrib_amount;
                    this.flagWarningEvents[index].contrib_amount_importance_prev = result2[0]['top_events'].filter(top => (top.id === warning.id))[0].contrib_amount_importance;
                  }
                });

                this.flagCautionEvents.forEach((caution, index) => {
                  if (result2[1]['top_events'].filter(top => (top.id === caution.id)).length > 0) {
                    this.flagCautionEvents[index].contrib_amount_prev = result2[1]['top_events'].filter(top => (top.id === caution.id))[0].contrib_amount;
                    this.flagCautionEvents[index].contrib_amount_importance_prev = result2[1]['top_events'].filter(top => (top.id === caution.id))[0].contrib_amount_importance;
                  }
                });

              });

            }, error => {
              this.nodata['Flag Events']['Warning'] = true;
              this.loading['Flag Events']['Warning'] = false;
              this.nodata['Flag Events']['Caution'] = true;
              this.loading['Flag Events']['Caution'] = false;
            });

          }

        }

        // EVENTS ---------------------------------------------

        if (!this.routing.isNetwork()) {
          this.yukkApi.relatedEvents(this.qparam, 'sub_events').subscribe(result => {
            const resCopy = JSON.parse(JSON.stringify(result.events));
            this.setSentimentEventsInfo(resCopy);
            this.events = result.events;
            let nonEmpty = false;
            this.events.forEach(item => {
              if (item.count > 0) {
                nonEmpty = true;
              }
            });
            this.nodata['Events'] = false;
            if (nonEmpty) {
              this.iready['Events'] = true;
            } else {
              this.iready['Events'] = false;
              this.loading['Events'] = false;
            }

            const timeframe = (this.qparam.time ? this.qparam.time : 7);
            const timeObj = this.yukkApi.myFromTo(timeframe);
            const timeParam = moment(timeObj.date1).subtract(timeObj.period, 'days').format('YYYY-MM-DD') + '--' + timeObj.date1;

            this.yukkApi.relatedEvents(Object.assign({}, this.qparam, {
              time: timeParam
            }), 'sub_events').subscribe(result2 => {
              this.events.forEach((event, index) => {
                if (result2['events'].filter(top => (top.event.id === event.event.id)).length > 0) {
                  this.events[index].count_prev = result2['events'].filter(top => (top.event.id === event.event.id))[0].count;
                }
              });
            });

          }, error => {
            this.nodata['Events'] = true;
            this.loading['Events'] = false;
          });
        }

        // SCORELAB EVENTS ---------------------------------------

        if (!(this.customScore === 'sentiment') && (this.routing.isFolio() || (qparam.type && ((qparam.type === 'company') || (qparam.type === 'pne') || ['industry', 'supersector', 'sector', 'subsector'].includes(qparam.type))) || (qparam.isin))) {

          if (this.isEsgSetup()) {

            forkJoin({
              esgEvents: this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                with_top_events: true,
                with_all_events: true
              }), 'chart'),
              esgScores: this.yukkApi.scoresTimeSeries('score', this.qparam, 'chart'),
              eEvents: this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                with_top_events: true,
                score_type: 'e_standard'
              }), 'chart'),
              eScores: this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                score_type: 'e_standard'
              }), 'chart'),
              sEvents: this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                with_top_events: true,
                score_type: 's_standard'
              }), 'chart'),
              sScores: this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                score_type: 's_standard'
              }), 'chart'),
              gEvents: this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                with_top_events: true,
                score_type: 'g_standard'
              }), 'chart'),
              gScores: this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                score_type: 'g_standard'
              }), 'chart')
            }).subscribe(result => {
              const resCopy = JSON.parse(JSON.stringify(result.esgEvents));
              const resCopy2 = JSON.parse(JSON.stringify(result));
              this.setScoreEventsInfo(resCopy);
              // @ts-ignore
              this.scoreEventsESG = resCopy2.esgEvents.top_events ? resCopy2.esgEvents.top_events : [];
              // @ts-ignore
              this.scoresESG.scoreESG = resCopy2.esgScores.score_ts_last_score.score ? resCopy2.esgScores.score_ts_last_score.score.toFixed(1) : null;
              // @ts-ignore
              this.scoreEventsE = resCopy2.eEvents.top_events ? resCopy2.eEvents.top_events : [];
              // @ts-ignore
              this.scoresESG.scoreE = resCopy2.eScores.score_ts_last_score.score ? resCopy2.eScores.score_ts_last_score.score.toFixed(1) : null;
              // @ts-ignore
              this.scoreEventsS = resCopy2.sEvents.top_events ? resCopy2.sEvents.top_events : [];
              // @ts-ignore
              this.scoresESG.scoreS = resCopy2.sScores.score_ts_last_score.score ? resCopy2.sScores.score_ts_last_score.score.toFixed(1) : null;
              // @ts-ignore
              this.scoreEventsG = resCopy2.gEvents.top_events ? resCopy2.gEvents.top_events : [];
              // @ts-ignore
              this.scoresESG.scoreG = resCopy2.gScores.score_ts_last_score.score ? resCopy2.gScores.score_ts_last_score.score.toFixed(1) : null;
              this.scoresESG.scoreLowest = [
                {type: 'e', score: this.scoresESG.scoreE},
                {type: 's', score: this.scoresESG.scoreS},
                {type: 'g', score: this.scoresESG.scoreG},
              ].reduce((min, x) => min.score < x.score ? min : x).type;
              let nonEmpty = false;
              this.scoreEventsESG.forEach(item => {
                if (item.contrib_amount > 0) {
                  nonEmpty = true;
                }
              });
              this.scoreEventsE.forEach(item => {
                if (item.contrib_amount > 0) {
                  nonEmpty = true;
                }
              });
              this.scoreEventsS.forEach(item => {
                if (item.contrib_amount > 0) {
                  nonEmpty = true;
                }
              });
              this.scoreEventsG.forEach(item => {
                if (item.contrib_amount > 0) {
                  nonEmpty = true;
                }
              });
              this.nodata['Score Events'] = false;

              // showing chosen event for event based alert at the top of events mosaic
              if (this.qparam?.score && this.qparam?.scoreevent && (this.qparam?.eventid)) {
                const isESG = (this.qparam.score === 'esg') ? 'esg' : '';
                const isAlert = (this.fromAlertScore) ? 'alert' : '';
                const targetId = this.qparam.eventid + '-' + isESG + '' + isAlert;
                // const targetId = this.qparam.eventid;
                setTimeout(() => {
                  const targetElement = document.getElementById(targetId);
                  // @ts-ignore
                  // targetElement.scrollIntoViewIfNeeded();
                  this.scrollIntoViewIfNotVisible(targetElement);
                }, 500);
              }

              if (nonEmpty) {
                this.iready['Score Events'] = true;
              } else {
                this.iready['Score Events'] = false;
                this.loading['Score Events'] = false;
              }

              const timeframe = this.checkTimeframe() ? this.checkFocusedTimeframe(this.qparam.time, 100) : (this.qparam.time ? this.qparam.time : null);
              const timeObj = this.yukkApi.myFromTo(timeframe);
              const timeParam = moment(timeObj.date1).subtract(timeObj.period, 'days').format('YYYY-MM-DD') + '--' + timeObj.date1;

              forkJoin({
                esgEvents: this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                  with_top_events: true,
                  with_all_events: true,
                  time: timeParam
                }), 'chart'),
                eEvents: this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                  with_top_events: true,
                  time: timeParam,
                  score_type: 'e_standard'
                }), 'chart'),
                sEvents: this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                  with_top_events: true,
                  time: timeParam,
                  score_type: 's_standard'
                }), 'chart'),
                gEvents: this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                  with_top_events: true,
                  time: timeParam,
                  score_type: 'g_standard'
                }), 'chart')
              }).subscribe(result2 => {

                this.scoreEventsESG.forEach((event, index) => {
                  if (result2['esgEvents']['top_events'].filter(top => (top.id === event.id)).length > 0) {
                    this.scoreEventsESG[index].contrib_amount_prev = result2['esgEvents']['top_events'].filter(top => (top.id === event.id))[0].contrib_amount;
                    this.scoreEventsESG[index].contrib_amount_importance_prev = result2['esgEvents']['top_events'].filter(top => (top.id === event.id))[0].contrib_amount_importance;
                  }
                });
                this.scoreEventsE.forEach((event, index) => {
                  if (result2['eEvents']['top_events'].filter(top => (top.id === event.id)).length > 0) {
                    this.scoreEventsE[index].contrib_amount_prev = result2['eEvents']['top_events'].filter(top => (top.id === event.id))[0].contrib_amount;
                    this.scoreEventsE[index].contrib_amount_importance_prev = result2['eEvents']['top_events'].filter(top => (top.id === event.id))[0].contrib_amount_importance;
                  }
                });
                this.scoreEventsS.forEach((event, index) => {
                  if (result2['sEvents']['top_events'].filter(top => (top.id === event.id)).length > 0) {
                    this.scoreEventsS[index].contrib_amount_prev = result2['sEvents']['top_events'].filter(top => (top.id === event.id))[0].contrib_amount;
                    this.scoreEventsS[index].contrib_amount_importance_prev = result2['sEvents']['top_events'].filter(top => (top.id === event.id))[0].contrib_amount_importance;
                  }
                });
                this.scoreEventsG.forEach((event, index) => {
                  if (result2['gEvents']['top_events'].filter(top => (top.id === event.id)).length > 0) {
                    this.scoreEventsG[index].contrib_amount_prev = result2['gEvents']['top_events'].filter(top => (top.id === event.id))[0].contrib_amount;
                    this.scoreEventsG[index].contrib_amount_importance_prev = result2['gEvents']['top_events'].filter(top => (top.id === event.id))[0].contrib_amount_importance;
                  }
                });

              });

            }, error => {
              this.nodata['Score Events'] = true;
              this.loading['Score Events'] = false;
            });

          } else {

            if (this.customScore === 'credit_risk') {

              this.yukkApi.scoresTimeSeries('score', this.qparam, 'chart').subscribe(result => {

                const crScore = (result['score_ts'][result['score_ts'].length - 1]['score'] !== null) ? result['score_ts'][result['score_ts'].length - 1]['score'] : null;

                this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                  with_top_events: true,
                  with_all_events: true,
                  top_events_sign: (crScore && (crScore > 0)) ? 'neg' : null
                }), 'chart').subscribe(result2 => {
                  const resCopy = JSON.parse(JSON.stringify(result2));
                  this.setScoreEventsInfo(resCopy);
                  this.scoreEvents = result2.top_events ? JSON.parse(JSON.stringify(result2.top_events)) : [];
                  let nonEmpty = false;
                  this.scoreEvents.forEach(item => {
                    if (item.contrib_amount > 0) {
                      nonEmpty = true;
                    }
                  });
                  this.nodata['Score Events'] = false;
                  if (nonEmpty) {
                    this.iready['Score Events'] = true;
                  } else {
                    this.iready['Score Events'] = false;
                    this.loading['Score Events'] = false;
                  }

                  const timeframe = this.checkTimeframe() ? this.checkFocusedTimeframe(this.qparam.time, 100) : (this.qparam.time ? this.qparam.time : null);
                  const timeObj = this.yukkApi.myFromTo(timeframe);
                  const timeParam = moment(timeObj.date1).subtract(timeObj.period, 'days').format('YYYY-MM-DD') + '--' + timeObj.date1;

                  this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                    with_top_events: true,
                    with_all_events: true,
                    time: timeParam,
                    top_events_sign: (crScore && (crScore > 0)) ? 'neg' : null
                  }), 'chart').subscribe(result3 => {
                    this.scoreEvents.forEach((event, index) => {
                      if (result3['top_events'].filter(top => (top.id === event.id)).length > 0) {
                        this.scoreEvents[index].contrib_amount_prev = result3['top_events'].filter(top => (top.id === event.id))[0].contrib_amount;
                        this.scoreEvents[index].contrib_amount_importance_prev = result3['top_events'].filter(top => (top.id === event.id))[0].contrib_amount_importance;
                      }
                    });
                  });

                }, error => {
                  this.nodata['Score Events'] = true;
                  this.loading['Score Events'] = false;
                });

              }, error => {
                this.nodata['Score Events'] = true;
                this.loading['Score Events'] = false;
              });

            } else {

              this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                with_top_events: true,
                with_all_events: true
              }), 'chart').subscribe(result => {
                const resCopy = JSON.parse(JSON.stringify(result));
                this.setScoreEventsInfo(resCopy);
                this.scoreEvents = result.top_events ? JSON.parse(JSON.stringify(result.top_events)) : [];
                let nonEmpty = false;
                this.scoreEvents.forEach(item => {
                  if (item.contrib_amount > 0) {
                    nonEmpty = true;
                  }
                });
                this.nodata['Score Events'] = false;
                if (nonEmpty) {
                  this.iready['Score Events'] = true;
                } else {
                  this.iready['Score Events'] = false;
                  this.loading['Score Events'] = false;
                }

                const timeframe = this.checkTimeframe() ? this.checkFocusedTimeframe(this.qparam.time, 100) : (this.qparam.time ? this.qparam.time : null);
                const timeObj = this.yukkApi.myFromTo(timeframe);
                const timeParam = moment(timeObj.date1).subtract(timeObj.period, 'days').format('YYYY-MM-DD') + '--' + timeObj.date1;

                this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                  with_top_events: true,
                  with_all_events: true,
                  time: timeParam
                }), 'chart').subscribe(result2 => {
                  this.scoreEvents.forEach((event, index) => {
                    if (result2['top_events'].filter(top => (top.id === event.id)).length > 0) {
                      this.scoreEvents[index].contrib_amount_prev = result2['top_events'].filter(top => (top.id === event.id))[0].contrib_amount;
                      this.scoreEvents[index].contrib_amount_importance_prev = result2['top_events'].filter(top => (top.id === event.id))[0].contrib_amount_importance;
                    }
                  });
                });

              }, error => {
                this.nodata['Score Events'] = true;
                this.loading['Score Events'] = false;
              });

            }

          }

        }

        // ALERT EVENTS ---------------------------------------

        if (!this.routing.isNetwork()) {

          if (this.fromAlertScore && !(this.qparam.score === 'sentiment') && (!(this.qparam.score === 'credit_risk') || ((this.qparam.score === 'credit_risk') && (qparam.type && ((qparam.type === 'company') || (qparam.type === 'pne') || ['industry', 'supersector', 'sector', 'subsector'].includes(qparam.type))))) && (this.routing.isFolio() || (qparam.type && ((qparam.type === 'company') || (qparam.type === 'pne') || ['industry', 'supersector', 'sector', 'subsector'].includes(qparam.type))) || (qparam.isin))) {

            if (this.isEsgSetupAlert()) {

              forkJoin({
                esgEvents: this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                  with_top_events: true,
                  with_all_events: true,
                  requestScore: 'esg'
                }), 'chart'),
                esgScores: this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                  requestScore: 'esg'
                }), 'chart'),
                eEvents: this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                  with_top_events: true,
                  score_type: 'e_standard'
                }), 'chart'),
                eScores: this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                  score_type: 'e_standard'
                }), 'chart'),
                sEvents: this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                  with_top_events: true,
                  score_type: 's_standard'
                }), 'chart'),
                sScores: this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                  score_type: 's_standard'
                }), 'chart'),
                gEvents: this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                  with_top_events: true,
                  score_type: 'g_standard'
                }), 'chart'),
                gScores: this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                  score_type: 'g_standard'
                }), 'chart')
              }).subscribe(result => {
                const resCopy = JSON.parse(JSON.stringify(result.esgEvents));
                const resCopy2 = JSON.parse(JSON.stringify(result));
                this.setAlertEventsInfo(resCopy);
                // @ts-ignore
                this.alertEventsESG = resCopy2.esgEvents.top_events ? resCopy2.esgEvents.top_events : [];
                // @ts-ignore
                this.alertESG.scoreESG = resCopy2.esgScores.score_ts_last_score.score ? resCopy2.esgScores.score_ts_last_score.score.toFixed(1) : null;
                // @ts-ignore
                this.alertEventsE = resCopy2.eEvents.top_events ? resCopy2.eEvents.top_events : [];
                // @ts-ignore
                this.alertESG.scoreE = resCopy2.eScores.score_ts_last_score.score ? resCopy2.eScores.score_ts_last_score.score.toFixed(1) : null;
                // @ts-ignore
                this.alertEventsS = resCopy2.sEvents.top_events ? resCopy2.sEvents.top_events : [];
                // @ts-ignore
                this.alertESG.scoreS = resCopy2.sScores.score_ts_last_score.score ? resCopy2.sScores.score_ts_last_score.score.toFixed(1) : null;
                // @ts-ignore
                this.alertEventsG = resCopy2.gEvents.top_events ? resCopy2.gEvents.top_events : [];
                // @ts-ignore
                this.alertESG.scoreG = resCopy2.gScores.score_ts_last_score.score ? resCopy2.gScores.score_ts_last_score.score.toFixed(1) : null;
                this.alertESG.scoreLowest = [
                  {type: 'e', score: this.alertESG.scoreE},
                  {type: 's', score: this.alertESG.scoreS},
                  {type: 'g', score: this.alertESG.scoreG},
                ].reduce((min, x) => min.score < x.score ? min : x).type;
                let nonEmpty = false;
                this.alertEventsESG.forEach(item => {
                  if (item.contrib_amount > 0) {
                    nonEmpty = true;
                  }
                });
                this.alertEventsE.forEach(item => {
                  if (item.contrib_amount > 0) {
                    nonEmpty = true;
                  }
                });
                this.alertEventsS.forEach(item => {
                  if (item.contrib_amount > 0) {
                    nonEmpty = true;
                  }
                });
                this.alertEventsG.forEach(item => {
                  if (item.contrib_amount > 0) {
                    nonEmpty = true;
                  }
                });
                this.nodata['Alert Events'] = false;
                if (nonEmpty) {
                  this.iready['Alert Events'] = true;
                } else {
                  this.iready['Alert Events'] = false;
                  this.loading['Alert Events'] = false;
                }

                const timeframe = (this.qparam.time ? this.qparam.time : 7);
                const timeObj = this.yukkApi.myFromTo(timeframe);
                const timeParam = moment(timeObj.date1).subtract(timeObj.period, 'days').format('YYYY-MM-DD') + '--' + timeObj.date1;

                // showing chosen event for event based alert at the top of events mosaic
                if (this.qparam?.score && this.qparam?.scoreevent && (this.qparam?.eventid)) {
                  const isESG = (this.qparam.score === 'esg') ? 'esg' : '';
                  const isAlert = (this.fromAlertScore) ? 'alert' : '';
                  const targetId = this.qparam.eventid + '-' + isESG + '' + isAlert;
                  // const targetId = this.qparam.eventid;
                  setTimeout(() => {
                    const targetElement = document.getElementById(targetId);
                    // @ts-ignore
                    // targetElement.scrollIntoViewIfNeeded();
                    this.scrollIntoViewIfNotVisible(targetElement);
                  }, 500);
                }

                forkJoin({
                  esgEvents: this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                    with_top_events: true,
                    with_all_events: true,
                    time: timeParam,
                    requestScore: 'esg'
                  }), 'chart'),
                  eEvents: this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                    with_top_events: true,
                    time: timeParam,
                    score_type: 'e_standard'
                  }), 'chart'),
                  sEvents: this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                    with_top_events: true,
                    time: timeParam,
                    score_type: 's_standard'
                  }), 'chart'),
                  gEvents: this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                    with_top_events: true,
                    time: timeParam,
                    score_type: 'g_standard'
                  }), 'chart')
                }).subscribe(result2 => {

                  this.alertEventsESG.forEach((event, index) => {
                    if (result2['esgEvents']['top_events'].filter(top => (top.id === event.id)).length > 0) {
                      this.alertEventsESG[index].contrib_amount_prev = result2['esgEvents']['top_events'].filter(top => (top.id === event.id))[0].contrib_amount;
                      this.alertEventsESG[index].contrib_amount_importance_prev = result2['esgEvents']['top_events'].filter(top => (top.id === event.id))[0].contrib_amount_importance;
                    }
                  });
                  this.alertEventsE.forEach((event, index) => {
                    if (result2['eEvents']['top_events'].filter(top => (top.id === event.id)).length > 0) {
                      this.alertEventsE[index].contrib_amount_prev = result2['eEvents']['top_events'].filter(top => (top.id === event.id))[0].contrib_amount;
                      this.alertEventsE[index].contrib_amount_importance_prev = result2['eEvents']['top_events'].filter(top => (top.id === event.id))[0].contrib_amount_importance;
                    }
                  });
                  this.alertEventsS.forEach((event, index) => {
                    if (result2['sEvents']['top_events'].filter(top => (top.id === event.id)).length > 0) {
                      this.alertEventsS[index].contrib_amount_prev = result2['sEvents']['top_events'].filter(top => (top.id === event.id))[0].contrib_amount;
                      this.alertEventsS[index].contrib_amount_importance_prev = result2['sEvents']['top_events'].filter(top => (top.id === event.id))[0].contrib_amount_importance;
                    }
                  });
                  this.alertEventsG.forEach((event, index) => {
                    if (result2['gEvents']['top_events'].filter(top => (top.id === event.id)).length > 0) {
                      this.alertEventsG[index].contrib_amount_prev = result2['gEvents']['top_events'].filter(top => (top.id === event.id))[0].contrib_amount;
                      this.alertEventsG[index].contrib_amount_importance_prev = result2['gEvents']['top_events'].filter(top => (top.id === event.id))[0].contrib_amount_importance;
                    }
                  });

                });

              }, error => {
                this.nodata['Alert Events'] = true;
                this.loading['Alert Events'] = false;
              });

            } else {

              if (this.qparam.score === 'credit_risk') {

                this.yukkApi.scoresTimeSeries('score', this.qparam, 'chart').subscribe(result => {

                  const crScore = (result['score_ts'][result['score_ts'].length - 1]['score'] !== null) ? result['score_ts'][result['score_ts'].length - 1]['score'] : null;

                  this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                    with_top_events: true,
                    with_all_events: true,
                    requestScore: 'credit_risk',
                    top_events_sign: (crScore && (crScore > 0)) ? 'neg' : null
                  }), 'chart').subscribe(result2 => {
                    const resCopy = JSON.parse(JSON.stringify(result2));
                    this.setAlertEventsInfo(resCopy);
                    this.alertEvents = result2.top_events ? JSON.parse(JSON.stringify(result2.top_events)) : [];
                    let nonEmpty = false;
                    this.alertEvents.forEach(item => {
                      if (item.contrib_amount > 0) {
                        nonEmpty = true;
                      }
                    });
                    this.nodata['Alert Events'] = false;
                    if (nonEmpty) {
                      this.iready['Alert Events'] = true;
                    } else {
                      this.iready['Alert Events'] = false;
                      this.loading['Alert Events'] = false;
                    }

                    const timeframe = (this.qparam.time ? this.qparam.time : 7);
                    const timeObj = this.yukkApi.myFromTo(timeframe);
                    const timeParam = moment(timeObj.date1).subtract(timeObj.period, 'days').format('YYYY-MM-DD') + '--' + timeObj.date1;

                    this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                      with_top_events: true,
                      with_all_events: true,
                      time: timeParam,
                      requestScore: 'credit_risk',
                      top_events_sign: (crScore && (crScore > 0)) ? 'neg' : null
                    }), 'chart').subscribe(result3 => {
                      this.alertEvents.forEach((event, index) => {
                        if (result3['top_events'].filter(top => (top.id === event.id)).length > 0) {
                          this.alertEvents[index].contrib_amount_prev = result3['top_events'].filter(top => (top.id === event.id))[0].contrib_amount;
                          this.alertEvents[index].contrib_amount_importance_prev = result3['top_events'].filter(top => (top.id === event.id))[0].contrib_amount_importance;
                        }
                      });
                    });

                  }, error => {
                    this.nodata['Alert Events'] = true;
                    this.loading['Alert Events'] = false;
                  });

                }, error => {
                  this.nodata['Alert Events'] = true;
                  this.loading['Alert Events'] = false;
                });

              } else {

                this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                  with_top_events: true,
                  with_all_events: true,
                  requestScore: this.qparam.score
                }), 'chart').subscribe(result => {
                  const resCopy = JSON.parse(JSON.stringify(result));
                  this.setAlertEventsInfo(resCopy);
                  this.alertEvents = result.top_events ? JSON.parse(JSON.stringify(result.top_events)) : [];
                  let nonEmpty = false;
                  this.alertEvents.forEach(item => {
                    if (item.contrib_amount > 0) {
                      nonEmpty = true;
                    }
                  });
                  this.nodata['Alert Events'] = false;
                  if (nonEmpty) {
                    this.iready['Alert Events'] = true;
                  } else {
                    this.iready['Alert Events'] = false;
                    this.loading['Alert Events'] = false;
                  }

                  const timeframe = (this.qparam.time ? this.qparam.time : 7);
                  const timeObj = this.yukkApi.myFromTo(timeframe);
                  const timeParam = moment(timeObj.date1).subtract(timeObj.period, 'days').format('YYYY-MM-DD') + '--' + timeObj.date1;

                  this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                    with_top_events: true,
                    with_all_events: true,
                    time: timeParam,
                    requestScore: this.qparam.score
                  }), 'chart').subscribe(result2 => {
                    this.alertEvents.forEach((event, index) => {
                      if (result2['top_events'].filter(top => (top.id === event.id)).length > 0) {
                        this.alertEvents[index].contrib_amount_prev = result2['top_events'].filter(top => (top.id === event.id))[0].contrib_amount;
                        this.alertEvents[index].contrib_amount_importance_prev = result2['top_events'].filter(top => (top.id === event.id))[0].contrib_amount_importance;
                      }
                    });
                  });

                }, error => {
                  this.nodata['Alert Events'] = true;
                  this.loading['Alert Events'] = false;
                });

              }

            }

          }

        }

        if (!this.routing.isNetwork()) {

          forkJoin({
            entity: this.yukkApi.isentiment(this.qparam, false),
            price: (this.qparam.type) ? this.yukkApi.scoresTimeSeries('price', this.qparam, 'chart') : of({prices: []}),
            treemap: !(['industry', 'supersector', 'sector', 'subsector'].includes(qparam.type)) ? this.yukkApi.treemap(this.qparam) : of(undefined)
          }).subscribe(result => {

            // ENTITY ---------------------------------------------

            if (!(this.routing.isFolio() && !this.qparam.portfolioId) && !(this.qparam.type === 'hierarchy')) {
              if (result.entity.entity.media) {
                if (result.entity.entity.media.uni) {
                  this.entity.logo = result.entity.entity.media.uni;
                }
              }
              this.entity.alpha_id = result.entity.entity.alpha_id;
              this.entity.compound_key = result.entity.entity.compound_key;
              this.entity.name = result.entity.entity.name;
              this.entity.sentiment = result.entity.sentiment.sentiment;
              this.entity.sentiment_delta = result.entity.sentiment.sentiment_delta;
            }

            // PRICE ---------------------------------------------

            if (!(this.routing.isFolio() && !this.qparam.portfolioId) && !(this.qparam.type === 'hierarchy')) {
              if (result.price['prices'].length > 1) {
                const price1 = result.price['prices'][result.price['prices'].length - 1].price;
                const price2 = result.price['prices'][result.price['prices'].length - 2].price;
                this.entity.currency = result.price['currency'];
                this.entity.price = price1;
                this.entity.price_change = this.iNumber((price1 - price2).toFixed(2));
                this.entity.price_change_diff = this.iPercent(((price1 - price2) / price2 * 100).toFixed(2));
              }
            }

            // TREEMAP ---------------------------------------------

            if (!(this.routing.isFolio() && !this.qparam.portfolioId) && !(this.qparam.type === 'hierarchy') && !(['industry', 'supersector', 'sector', 'subsector'].includes(qparam.type))) {
              const treemap = [];
              result.treemap.treemap.children.forEach(item => {
                item.children.forEach(itom => {
                  treemap.push(itom);
                });
              });
              this.treemapsort = treemap.sort((a, b) => a.entity.sentiment_delta - b.entity.sentiment_delta);
            }

            this.iready['Entity'] = true;

            // PEERGROUP ---------------------------------------------

            if (['industry', 'supersector', 'sector', 'subsector'].includes(qparam.type)) {
              const peergroupArray = this.auth.folio.contentInfo.map(el => {
                return {
                  entity: el
                };
              });
              this.peergroup = peergroupArray;
              this.iready['Peergroup Comparison'] = true;
            } else if (!(this.routing.isFolio() && !this.qparam.portfolioId) && !(this.qparam.type === 'hierarchy')) {
              const iparams = JSON.parse(JSON.stringify(this.qparam));
              // if (result.entity.entity.description && result.entity.entity.description['Subsector']) {
              //   iparams.type = 'subsector';
              //   iparams.id = result.entity.entity.description['Subsector'].entity.alpha_id;
              // } else if (result.entity.entity.description && result.entity.entity.description['Sector']) {
              //   iparams.type = 'sector';
              //   iparams.id = result.entity.entity.description['Sector'].entity.alpha_id;
              // } else if (result.entity.entity.description && result.entity.entity.description['Industry']) {
              //   iparams.type = 'industry';
              //   iparams.id = result.entity.entity.description['Industry'].entity.alpha_id;
              // } else if (result.entity.entity.description && result.entity.entity.description['Country']) {
              //   // iparams.type = 'country';
              //   // iparams.id = result.entity.entity.description['Country'].entity.alpha_id;
              //   iparams.type = null;
              //   iparams.id = null;
              // } else {
              //   iparams.type = null;
              //   iparams.id = null;
              // }
              if (iparams.type && iparams.id) {
                this.yukkApi.peergroupComparison(iparams).subscribe(peergroup => {
                  const peergroupChildren = peergroup.map(el => Object.assign({}, el, {entity: {type: el.type, name: el.name, alpha_id: el.alpha_id, compound_key: el.compound_key}})).filter(children => {
                    return (children.entity.type === 'company');
                  });
                  if (peergroupChildren && (peergroupChildren.length > 0)) {
                    this.peergroup = peergroupChildren.filter(item => {
                      if (item.entity) {
                        if (item.entity.alpha_id !== this.qparam.id) {
                          return item;
                        } else {
                          item.count = Infinity;
                          return item;
                        }
                      }
                    });
                    const content = this.peergroup.map(el => {
                      return el.entity.compound_key;
                    });
                    if (this.customScore && (this.customScore !== 'sentiment')) {
                      this.yukkApi.scoresTimeSeries('score', Object.assign({}, iparams, {
                        usePortfolio: true,
                        itemized: true,
                        requestScore: this.customScore,
                        custom_payload: content
                      }), 'chart').subscribe(res => {
                        res.forEach(entity => {
                          if (this.entity.compound_key === entity.entity_info.compound_key) {
                            this.entity.score = entity.score_ts_last_score.score?.toFixed(1);
                          }
                          this.peergroup.forEach((item, index) => {
                            if (item.entity.compound_key === entity.entity_info.compound_key) {
                              this.peergroup[index].score = entity.score_ts_last_score.score?.toFixed(1);
                            }
                          });
                        });
                      });
                      this.iready['Peergroup Comparison'] = true;
                    } else {
                      this.iready['Peergroup Comparison'] = true;
                    }
                  } else {
                    if (!(this.customScore === 'sentiment') && (this.routing.isFolio() || (qparam.type && ((qparam.type === 'company') || (qparam.type === 'pne') || ['industry', 'supersector', 'sector', 'subsector'].includes(qparam.type))) || (qparam.isin))) {
                      this.array_filter = ['Score Events', 'Topic List', 'Events'];
                    } else {
                      this.array_filter = ['Events', 'Topic List'];
                    }
                    this.iready['Peergroup Comparison'] = true;
                  }
                }, error => {
                  this.nodata['Peergroup Comparison'] = true;
                  this.loading['Peergroup Comparison'] = false;
                });
              } else {
                if (!(this.customScore === 'sentiment') && (this.routing.isFolio() || (qparam.type && ((qparam.type === 'company') || (qparam.type === 'pne') || ['industry', 'supersector', 'sector', 'subsector'].includes(qparam.type))) || (qparam.isin))) {
                  this.array_filter = ['Score Events', 'Topic List', 'Events'];
                } else {
                  this.array_filter = ['Events', 'Topic List'];
                }
                this.iready['Peergroup Comparison'] = null;
              }
            } else {
              if (!(this.customScore === 'sentiment') && (this.routing.isFolio() || (qparam.type && ((qparam.type === 'company') || (qparam.type === 'pne') || ['industry', 'supersector', 'sector', 'subsector'].includes(qparam.type))) || (qparam.isin))) {
                this.array_filter = ['Score Events', 'Topic List', 'Events'];
              } else {
                this.array_filter = ['Events', 'Topic List'];
              }
              this.iready['Peergroup Comparison'] = true;
            }

          }, error => {
            this.nodata['Peergroup Comparison'] = true;
            this.loading['Peergroup Comparison'] = false;
            this.nodata['Entity'] = true;
            this.loading['Entity'] = false;
          });

        }

      }
      this.previousValue = this.qparam;
    });

  }

  private processSupplyChainData() {
    const motherCompany = this.supplyChain.motherCompany;
    const suppliers = this.supplyChain.suppliers;
    if (this.qparam.tag && !(this.qparam.tag.includes(motherCompany.entity.compound_key))) {
      suppliers.forEach(supplier => {
        if (this.qparam.tag.includes(supplier.entity.compound_key)) {
          this.networkEntity = {
            entity: supplier.entity,
            items: []
          };
          supplier.countries.forEach(country => {
            this.networkEntity.items.push({
              label: 'Country (Primary Location)',
              value: country
            });
          });
          supplier.industries.forEach(industry => {
            this.networkEntity.items.push({
              label: 'Industry',
              value: industry
            });
          });
          supplier.supplied_products.forEach(product => {
            this.networkEntity.items.push({
              label: 'Supplied Product',
              value: product
            });
          });
          this.networkEntity.items.push({
            label: 'No. Employees',
            value: supplier.num_employees
          });
          supplier.persons.forEach(person => {
            this.networkEntity.items.push({
              label: 'Person (CEO)',
              value: person
            });
          });
          supplier.cities.forEach(city => {
            this.networkEntity.items.push({
              label: 'City (Headquarter)',
              value: city
            });
          });
          supplier.supersectors.forEach(supersector => {
            this.networkEntity.items.push({
              label: 'Supersector',
              value: supersector
            });
          });
          supplier.sectors.forEach(sector => {
            this.networkEntity.items.push({
              label: 'Sector',
              value: sector
            });
          });
          supplier.subsectors.forEach(subsector => {
            this.networkEntity.items.push({
              label: 'Subsector',
              value: subsector
            });
          });
        }
      });
    } else {
      this.networkEntity = {
        entity: motherCompany.entity,
        items: []
      };
      motherCompany.countries.forEach(country => {
        this.networkEntity.items.push({
          label: 'Country (Primary Location)',
          value: country
        });
      });
      motherCompany.industries.forEach(industry => {
        this.networkEntity.items.push({
          label: 'Industry',
          value: industry
        });
      });
      this.networkEntity.items.push({
        label: 'No. Employees',
        value: motherCompany.num_employees
      });
      motherCompany.persons.forEach(person => {
        this.networkEntity.items.push({
          label: 'Person (CEO)',
          value: person
        });
      });
      motherCompany.cities.forEach(city => {
        this.networkEntity.items.push({
          label: 'City (Headquarter)',
          value: city
        });
      });
      motherCompany.supersectors.forEach(supersector => {
        this.networkEntity.items.push({
          label: 'Supersector',
          value: supersector
        });
      });
      motherCompany.sectors.forEach(sector => {
        this.networkEntity.items.push({
          label: 'Sector',
          value: sector
        });
      });
      motherCompany.subsectors.forEach(subsector => {
        this.networkEntity.items.push({
          label: 'Subsector',
          value: subsector
        });
      });

    }
  
  }

  ngAfterViewChecked() {
    if (this.expansionContainer) {
      this.contentHeight = Math.max((this.expansionContainer.nativeElement.offsetHeight / 7) - 1, 40);
      this.cdr.detectChanges();
    }
  }

  /**
   *
   */
  iNumber(value) {
    const icon = value > 0 ? '+' : '';
    return icon + value;
  }

  /**
   *
   */
  iPercent(value) {
    const icon = value > 0 ? '+' : '';
    return icon + value + '%';
  }

  /**
   *
   */
  iColor(value) {
    if (value > 0) { return '#3dac00'; }
    if (value === 0) { return '#f6d500'; }
    if (value < 0) { return '#dd001a'; }
  }

  /**
   *
   */
  iAbs(value) {
    return Math.abs(value * 100).toFixed(0);
  }

  /**
   * redirect specific entity
   */
  iEntity(type, id) {
    this.router.navigate([], {
      queryParams: {
        type: type,
        id: id,
        tag: null,
        news: null,
        eventid: null,
      }, queryParamsHandling: 'merge'
    });
  }

  /**
   * click the tagcloud
   */
  iTacloud(item) {
    const tag = this.tagword === item.tag ? null : encodeURI(item.tag) + '|' + item.word;
    this.router.navigate([], {
      queryParams: {
        tag: tag
      },
      queryParamsHandling: 'merge',
      replaceUrl: false
    });
  }

  /**
   *
   */
  iPerform3(value) {
    const perform = Math.round(this.entity.sentiment * 100) / 100 - Math.round(value * 100) / 100;
    let icon = '';
    if (perform < 0) { icon = 'better'; }
    if (perform === 0) { icon = 'equal'; }
    if (perform > 0) { icon = 'worse'; }
    return icon;
  }

  /**
   *
   */
  iPerform1(value) {
    const perform = value - this.entity.sentiment;
    const icon = perform > 0 ? '▲' : '▼';
    return icon;
  }

  /**
   *
   */
  iPerform2(value) {
    const perform = value - this.entity.sentiment;
    return Math.abs(perform * 100).toFixed(0);
  }

  /**
   * percentage of the event bar
   */
  iBaro(array, value) {
    const counts = array.map(event => event.count);
    const maxcount = Math.max.apply(Math, counts);
    return ((value * 100) / maxcount);
  }

  /**
   * percentage of the scorelab event bar
   */
  iBaroScore(array, value) {
    const scores = array.map(event => event.contrib_amount);
    const maxscore = Math.max.apply(Math, scores);
    return ((value * 100) / maxscore);
  }

  /**
   * click specific event
   */
  iEvent(item, clearEventFilters = true) {
    let eventid = null;
    let eventype = null;
    const newstype = this.qparam.newstype ? this.qparam.newstype : ((this.qparam.type && !['industry', 'sector', 'supersector', 'subsector'].includes(this.qparam.type)) ? 'Events' : null);
    if (this.qparam.eventid === item.event.id) {
      // item.expand = false;
    } else {
      // item.expand = true;
      eventid = item.event.id;
      eventype = item.event.type;
    }
    if (clearEventFilters) {
      this.router.navigate([], {
        queryParams: {
          eventid: eventid,
          eventype: eventype,
          newstype: newstype,
          newsday: null
        },
        queryParamsHandling: 'merge',
        replaceUrl: false
      });
    } else {
      this.router.navigate([], {
        queryParams: {
          eventid: eventid,
          eventype: eventype,
          newstype: newstype,
          newsday: null
        },
        queryParamsHandling: 'merge',
        replaceUrl: false
      });
    }
  }

  /**
   * click specific scorelab event
   */
  iScoreEvent(item, clearEventFilters = true) {
    let eventid = null;
    let eventype = null;
    let scoreevent = null;
    const newstype = this.qparam.newstype ? this.qparam.newstype : ((this.qparam.type && !['industry', 'sector', 'supersector', 'subsector'].includes(this.qparam.type)) ? 'Events' : null);
    if (this.qparam.eventid === item.id) {
      // item.expand = false;
    } else {
      // item.expand = true;
      eventid = item.id;
      eventype = item.type;
      scoreevent = true;
    }
    if (clearEventFilters) {
      this.router.navigate([], {
        queryParams: {
          eventid: eventid,
          eventype: eventype,
          scoreevent: scoreevent,
          flagevent: null,
          newstype: newstype,
          newsday: null
        },
        queryParamsHandling: 'merge',
        replaceUrl: false
      });
    } else {
      this.router.navigate([], {
        queryParams: {
          eventid: eventid,
          eventype: eventype,
          scoreevent: scoreevent,
          flagevent: null,
          newstype: newstype,
          newsday: null
        },
        queryParamsHandling: 'merge',
        replaceUrl: false
      });
    }

  }

  /**
   * click specific caution flag's scorelab event
   */
  iFlagEvent(item, clearEventFilters = true) {
    let eventid = null;
    let eventype = null;
    let flagevent = null;
    const newstype = this.qparam.newstype ? this.qparam.newstype : ((this.qparam.type && !['industry', 'sector', 'supersector', 'subsector'].includes(this.qparam.type)) ? 'Events' : null);
    if (this.qparam.eventid === item.id) {
      // item.expand = false;
    } else {
      // item.expand = true;
      eventid = item.id;
      eventype = item.type;
      flagevent = true;
    }
    if (clearEventFilters) {
      this.router.navigate([], {
        queryParams: {
          eventid: eventid,
          eventype: eventype,
          scoreevent: null,
          flagevent: flagevent,
          newstype: newstype,
          newsday: null
        },
        queryParamsHandling: 'merge',
        replaceUrl: false
      });
    } else {
      this.router.navigate([], {
        queryParams: {
          eventid: eventid,
          eventype: eventype,
          scoreevent: null,
          flagevent: flagevent,
          newstype: newstype,
          newsday: null
        },
        queryParamsHandling: 'merge',
        replaceUrl: false
      });
    }
  }

  /**
   * changelter the visualization columns
   */
  iFilter(event) {
    if (event === false) {
      if (!(this.customScore === 'sentiment') && (this.routing.isFolio() || (this.qparam.type && ((this.qparam.type === 'company') || (this.qparam.type === 'pne') || ['industry', 'supersector', 'sector', 'subsector'].includes(this.qparam.type))) || (this.qparam.isin))) {
        if (!(this.routing.isFolio() && !this.qparam.portfolioId) && !(this.qparam.type === 'hierarchy')) {
          if (this.config.appConfig.setup.useStorage) {
            localStorage.setItem('score_company_filter', this.ifilter.value);
          }
        } else {
          if (this.config.appConfig.setup.useStorage) {
            localStorage.setItem('score_mosaics_filter', this.ifilter.value);
          }
        }
      } else {
        if (!(this.routing.isFolio() && !this.qparam.portfolioId) && !(this.qparam.type === 'hierarchy')) {
          if (this.config.appConfig.setup.useStorage) {
            localStorage.setItem('company_filter', this.ifilter.value);
          }
        } else {
          if (this.config.appConfig.setup.useStorage) {
            localStorage.setItem('mosaics_filter', this.ifilter.value);
          }
        }
      }
    }
  }

  /**
   *
   */
  iTool(value) {
    if (this.routing.isNetwork()) {
      if (['Score Events', 'Supplier'].includes(value)) {
        return true;
      } else {
        return false;
      }
    } else if ((value === 'Flag Events') && this.auth.scorelabSettings.defaultFlag && (this.auth.scorelabSettings.defaultFlag.score_type !== 'sentiment') && (this.auth.scorelabSettings.defaultFlag.score_type !== 'volume') && (this.auth.scorelabSettings.defaultFlag.score_type !== this.customScore) && this.qparam && (this.routing.isFolio() || (this.qparam.type && ((this.qparam.type === 'company') || (this.qparam.type === 'pne') || ['industry', 'supersector', 'sector', 'subsector'].includes(this.qparam.type))) || (this.qparam.isin))) {
      if (this.showWarningFlag || this.showCautionFlag) {
        return true;
      } else {
        return false;
      }
    } else if ((value === 'Events') && this.auth.scorelabSettings.defaultFlag && (this.auth.scorelabSettings.defaultFlag.score_type === 'sentiment')) {
      if (this.showWarningFlag || this.showCautionFlag) {
        return true;
      } else {
        return this.ifilter.value.includes(value);
      }
    } else {
      if ((value === 'Score Events') && this.qparam.score) {
        return true;
      } else {
        return this.ifilter.value.includes(value);
      }
    }
  }

  /**
   * get events mosaic title for current default score
   */
  scoreEventsTitle() {

    if (this.qparam.custom_score) {
      return (this.auth.getScoresInfo(this.qparam.custom_score)['name'] + ' Events');
  }
    
    return ((this.routing.isWidget() && this.qparam.score_type) ? ((this.qparam.score_type === 'esg') ? 'ESG Events' : 'Risk Events') : (this.auth.getScoreName().name + ' Events'));
  }

  /**
   * get events mosaic title for current alert score
   */
  alertEventsTitle() {
    return ((this.routing.isWidget() && this.qparam.score_type) ? ((this.qparam.score_type === 'esg') ? 'ESG Events' : 'Risk Events') : (this.auth.getScoresInfo(this.qparam.score)['name'] + ' Events'));
  }

  /**
   * check if ESG mosaic should be displayed
   */
  isEsgSetup() {
    return ((!this.routing.isWidget() && (this.customScore === 'esg')) || (this.routing.isWidget() && (this.customScore === 'esg') && !this.qparam.score_type) || (this.routing.isWidget() && (this.qparam.score_type === 'esg')));
  }

  /**
   * check if ESG mosaic should be displayed for alert score
   */
  isEsgSetupAlert() {
    return ((!this.routing.isWidget() && (this.qparam.score === 'esg')) || (this.routing.isWidget() && (this.qparam.score === 'esg') && !this.qparam.score_type) || (this.routing.isWidget() && (this.qparam.score_type === 'esg')));
  }

  checkTimeframe() {
    if (this.qparam.time) {
      const days = this.yukkApi.myFromTo(this.qparam.time).period;
      if (days < 100) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  checkLongTimeframe() {
    if (this.qparam.time) {
      const days = this.yukkApi.myFromTo(this.qparam.time).period;
      if (days < 365) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  checkLongTimeframe2() {
    if (this.qparam.time) {
      const days = this.yukkApi.myFromTo(this.qparam.time).period;
      if (days < 465) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  setSentimentEventsInfo(sentimentEventsObj) {
    let count = 0;
    sentimentEventsObj.forEach(event => {
      count += event.count;
    });
    this.sentimentEventsCount = count;
    this.sentimentEventsCountAll = count;
    this.sentimentEventsTimeframe = this.qparam.time ? this.yukkApi.myFromTo(this.qparam.time).period : 7;
  }

  setScoreEventsInfo(scoreEventsObj) {
    let count = 0;
    scoreEventsObj.top_events?.forEach(event => {
      count += event.contrib_amount;
    });
    this.scoreEventsCount = count;
    this.scoreEventsTimeframe = this.checkFocusedTimeframe(this.qparam.time, 100);
    this.yukkApi.relatedEvents(Object.assign({}, this.qparam, {
      time: this.scoreEventsTimeframe,
      newsday: null
    }), 'sub_events').subscribe(result => {
      const resCopy = JSON.parse(JSON.stringify(result.events));
      let countEvents = 0;
      resCopy.forEach(item => {
        if (item.count > 0) {
          countEvents += item.count;
        }
      });
      this.scoreEventsCountAll = countEvents;
    });
  }

  setAlertEventsInfo(scoreEventsObj) {
    let count = 0;
    scoreEventsObj.top_events?.forEach(event => {
      count += event.contrib_amount;
    });
    this.alertEventsCount = count;
    this.alertEventsTimeframe = this.checkFocusedTimeframe(this.qparam.time, 100);
    this.yukkApi.relatedEvents(Object.assign({}, this.qparam, {
      time: this.alertEventsTimeframe,
      newsday: null
    }), 'sub_events').subscribe(result => {
      const resCopy = JSON.parse(JSON.stringify(result.events));
      let countEvents = 0;
      resCopy.forEach(item => {
        if (item.count > 0) {
          countEvents += item.count;
        }
      });
      this.alertEventsCountAll = countEvents;
    });
  }

  setFlagEventsInfo(flagEventsObj, flagType) {
    if (this.auth.scorelabSettings.defaultFlag.score_type === 'sentiment') {
      let count = 0;
      flagEventsObj.forEach(event => {
        count += event.count;
      });
      if (flagType === 'warning') {
        this.flagWarningEventsCount = count;
        this.flagWarningEventsCountAll = count;
        this.flagWarningEventsTimeframe = this.qparam.time ? this.yukkApi.myFromTo(this.qparam.time).period : 7;
      } else if (flagType === 'caution') {
        this.flagCautionEventsCount = count;
        this.flagCautionEventsCountAll = count;
        this.flagCautionEventsTimeframe = this.checkLongTimeframe() ? this.checkFocusedTimeframe(this.qparam.time, 365) : (this.qparam.time ? this.yukkApi.myFromTo(this.qparam.time).period : 7);
      }
    } else {
      let count = 0;
      flagEventsObj.top_events?.forEach(event => {
        count += event.contrib_amount;
      });
      if (flagType === 'warning') {
        this.flagWarningEventsCount = count;
      } else if (flagType === 'caution') {
        this.flagCautionEventsCount = count;
      }
      if (flagType === 'warning') {
        this.flagWarningEventsTimeframe = this.checkFocusedTimeframe(this.qparam.time, 100);
      } else if (flagType === 'caution') {
        this.flagCautionEventsTimeframe = this.checkFocusedTimeframe(this.qparam.time, 465);
      }
      if (flagType === 'warning') {
        this.yukkApi.relatedEvents(Object.assign({}, this.qparam, {
          time: this.flagWarningEventsTimeframe,
          newsday: null
        }), 'sub_events').subscribe(result => {
          const resCopy = JSON.parse(JSON.stringify(result.events));
          let countEvents = 0;
          resCopy.forEach(item => {
            if (item.count > 0) {
              countEvents += item.count;
            }
          });
          this.flagWarningEventsCountAll = countEvents;
        });
      } else if (flagType === 'caution') {
        this.yukkApi.relatedEvents(Object.assign({}, this.qparam, {
          time: this.flagCautionEventsTimeframe,
          newsday: null
        }), 'sub_events').subscribe(result => {
          const resCopy = JSON.parse(JSON.stringify(result.events));
          let countEvents = 0;
          resCopy.forEach(item => {
            if (item.count > 0) {
              countEvents += item.count;
            }
          });
          this.flagCautionEventsCountAll = countEvents;
        });
      }
    }
  }

  checkFocusedTimeframe(time, timeframe) {
    let days = null;
    if (time) {
      days = this.yukkApi.myFromTo(time).period;
    }
    if (this.qparam?.score && ['sentiment'].includes(this.qparam.score)) {
      if (days) {
        return time;
      } else {
        return 7;
      }
    } else if (days === 100) {
      return time;
    } else if (days === 365) {
      return time;
    } else if (days === 465) {
      return time;
    } else if (timeframe === 100) {
      return 100;
    } else if (timeframe === 365) {
      return 365;
    } else if (timeframe === 465) {
      return 465;
    } else {
      return null;
    }
  }


  eventsAnalyticsButtonWidth(type, id, event) {
    const val = (this.auth.scoreFocusedEventsAnalytics[type + '' + id][event] ? this.auth.scoreFocusedEventsAnalytics[type + '' + id][event] : 0);
    const sum = (this.auth.scoreFocusedEventsAnalytics[type + '1'][event] ? this.auth.scoreFocusedEventsAnalytics[type + '1'][event] : 0) + (this.auth.scoreFocusedEventsAnalytics[type + '2'][event] ? this.auth.scoreFocusedEventsAnalytics[type + '2'][event] : 0) + (this.auth.scoreFocusedEventsAnalytics[type + '3'][event] ? this.auth.scoreFocusedEventsAnalytics[type + '3'][event] : 0) + (this.auth.scoreFocusedEventsAnalytics[type + '4'][event] ? this.auth.scoreFocusedEventsAnalytics[type + '4'][event] : 0);
    let proportion = 0;
    if (sum !== 0) {
      proportion = Math.ceil(val * 100 / sum);
    }
    if (id === '4') {
      proportion = 100 - this.eventsAnalyticsButtonWidth(type, '1', event) - this.eventsAnalyticsButtonWidth(type, '2', event) - this.eventsAnalyticsButtonWidth(type, '3', event);
    }
    return proportion;
  }

  scrollIntoViewIfNotVisible(target) {
    if (target.getBoundingClientRect().bottom > window.innerHeight) {
      target.scrollIntoView();
    }
    if (target.getBoundingClientRect().top < 0) {
      target.scrollIntoView();
    }
  }

  sortEventsBy(option) {
    this.eventsSortedBy = option;
  }

}
